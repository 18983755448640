import {find} from "lodash";
import {kioskZonedDateTime} from "../../utils/timeUtils";
import {mapGetters} from "vuex";

const TYPE_MAP = [
    {key: 'BOOKING', display: 'Booking'},
    {key: 'REPRINT', display: 'Reprint'},
    {key: 'TICKET_LOOKUP', display: 'Reprint'},
    {key: 'WILL_CALL', display: 'Will Call'},
    {key: 'ADD_BAG', display: 'Add Baggage'},
    {key: 'TEST_PRINT', display: 'Test Print'},
    {key: 'RESET', display: 'Reset'},
    {key: 'PRINT_COUNTER_RESET', display: 'Printer Count Reset'},
    {key: 'CASH_DISPENSER_REJECT_RECEIPT', display: 'Cleared Rejected Dispenses'},
    {key: 'REJECT_RESET', display: 'Cleared Rejected Dispenses'},
    {key: 'CASH_ACCEPTOR_RECEIPT', display: 'Cash Acceptor Receipt'},
    {key: 'CASH_DISPENSER_RECEIPT', display: 'Cash Dispenser Receipt'},
    {key: 'CASH_DISPENSER_RECEIPT_NO_UPDATE', display: 'Cash Dispenser Receipt'},
    {key: 'CASH_ACCEPTOR_RECEIPT_NO_UPDATE', display: 'Cash Acceptor Receipt'},
];

const PRINT_STATUS = {
    COMPLETE: "Complete",
    PARTIAL: "Partial",
    FAILED: "Failed"
};

const capitalize = (str) => {
  return str.split("_")
      .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
      .join(" ");
}

export default {
    computed: {
        ...mapGetters("GeneralSettings", ["kioskDetails"]),
        kioskTimeZone() {
            return this.kioskDetails.stopLocation.timeZone;
        },
    },
    methods: {
        formatLogType(value){
            const type = find(TYPE_MAP, function(o) { return o.key === value; });
            return type?.display ?? value;
        },
        formatLogDate(value) {
            return kioskZonedDateTime(value, this.kioskTimeZone);
        },
        formatLogLogoutDate(value) {
            console.log();
            return value ? kioskZonedDateTime(value, this.kioskTimeZone) : "No Logout";
        },
        formatLogUser(value) {
            return value.includes("service-account") ? "Kiosk" : value;
        },
        formatSuccessfulTransaction(value){
          return value === "SUCCESS" ? "Success" : "Fail";
        },
        formatSuccessfulPrint(value){
            return value ? "Fail" : "Success";
        },
        formatMaintenanceEvent(value) {
            return value ? "Activated": "Deactivated";
        },
        capitalizeWords(value) {
            return value ? capitalize(value) : value;
        },
        formatPrintStatus({total, success}) {
            if (!total || !success) {
                return PRINT_STATUS.FAILED;
            }
            return total === success ? PRINT_STATUS.COMPLETE : PRINT_STATUS.PARTIAL
        },
    },
};
